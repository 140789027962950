@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
    url(../font/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'M PLUS 1';
  src: local('M PLUS 1'),
    url(../font/MPLUS1-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Rexliafree';
  src: local('Rexliafree'), url(../font/rexliafree.ttf) format('truetype');
}

@import url('./shop.css');
@import url('./monster-detail.css');
@import url('./tree.css');
@import url('./profile/index.css');

body {
  margin: 0;
  font-family: 'Rexliafree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000926;
  color: #fff;
  font-weight: 400;
}

::-webkit-scrollbar {
  display: none;
}

.font__M__plus {
  font-family: 'M PLUS 1', sans-serif !important;
}

hr {
  border: 0.9px solid #3d5279;
}

.container__common {
  position: relative;
  z-index: 10;
  padding: 1em;
}
.container__common_header {
  position: relative;
  z-index: 10;
  padding: 1em;
}

.active {
  color: #40a9ff;
  border-bottom: 2px solid;
}

.active__filter {
  background: #1950b3;
  border: 1px solid #40a9ff;
}

.blockchain__button {
  box-shadow: 0px 4px 16px 0px #f49b4b66;
  background: #faa52c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  height: 100%;
}

.blockchain__button:hover {
  opacity: 0.85 !important;
  color: #fff !important;
  border-color: #faa52c !important;
}

.main-nav {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.navbar__left_element:hover {
  color: #40a9ff;
}

.common__btn_main {
  transition: 1s;
}

.common__btn_middle {
  background: rgba(40, 85, 138, 1);
  transition: 0.15s;
  backdrop-filter: blur(15px);
}
.common__btn_middle_delete {
  background: rgba(255, 0, 0, 0.3);
  transition: 0.15s;
  backdrop-filter: blur(15px);
}
.common__btn:hover {
  filter: drop-shadow(-1px 1px 5px #40a9ff);
}

.common__btn_delete {
  transition: 1s;
}

.common__btn_delete:hover {
  filter: drop-shadow(-1px 1px 5px #ef001b99);
}

.common__btn_left {
  opacity: 0.5;
}

.common__btn_right {
  opacity: 0.5;
}

.common__btn:hover .common__btn_left {
  opacity: 1;
  animation: common__btn_left 0.5s ease-in-out;
}

.common__btn:hover .common__btn_right {
  opacity: 1;
  animation: common__btn_right 0.5s ease-in-out;
}

.common__btn:hover .common__sub_btn_left {
  /* opacity: 0; */
  animation: common__btn_left 0.5s ease-in-out;
}

.common__btn:hover .common__sub_btn_right {
  /* opacity: 0; */
  animation: common__btn_right 0.5s ease-in-out;
}

.common__btn:hover .common__btn_middle {
  background: rgba(64, 169, 255, 0.7);
}

.block__ground::before {
  position: fixed;
  content: '';
  background: url(../imgs/banner/left.png) center center no-repeat;
  width: 100%;
  height: 266px;
  left: 0;
  top: 84px;
  z-index: 0;
}

.block__ground::after {
  position: fixed;
  content: '';
  background: url(../imgs/banner/right.png) center center no-repeat;
  width: 100%;
  height: 266px;
  right: 0;
  top: 84px;
  z-index: 0;
}

.card__detail {
  background: url(../imgs/bgDetail.png) center center no-repeat;
  cursor: pointer;
  background-size: 100% 100%;
  max-width: 650px;
}

.card__detail::after {
  content: '';
  display: block;
  position: absolute;
  cursor: pointer;
  background: url(../imgs/bgDetail.png) 50% no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  max-width: 650px;
}

.card__detail:hover .card__detail_img {
  transform: scale(1.05);
  transition: 0.5s;
}

.card__monster {
  background: url(../imgs/bgMonster.png) center center no-repeat;
  background-size: contain;
}
.card__monster_farm {
  background: url(../imgs/bg_border__item.png) center center no-repeat;
  background-size: contain;
}

.card__monster:hover .card__monster_img {
  transform: scale(1.05);
  transition: 0.5s;
}
.card__monster_farm:hover .card__monster_img {
  transform: scale(1.05);
  transition: 0.5s;
}
.card__monster_farm:hover {
  box-shadow: inset 0 0 5px 2px #40a9ff;
  transition: 0.5s;
  border-radius: 10px;
}
.card__monster:hover {
  box-shadow: inset 0 0 5px 2px #40a9ff;
  transition: 0.5s;
  border-radius: 10px;
}

.sort__value:hover .title {
  color: #40a9ff;
}

.sort__value:hover svg path {
  stroke: #40a9ff;
}

@media screen and (min-width: 768px) {
  .blockchain__button {
    width: 250px;
  }

  .block__ground::before {
    width: 485px;
  }

  .block__ground::after {
    width: 485px;
  }

  .container__common {
    padding: 0 7em;
  }
  .container__common_header {
    padding: 0 3em;
  }
}

.connect__button {
  box-shadow: 0px 4px 16px 0px #f49b4b66;
  background: #faa52c;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 10px;
  height: 100%;
}

.connect__button:hover {
  opacity: 0.85 !important;
  color: #fff !important;
  border-color: #faa52c !important;
}

.st-wallet {
  border: 1px solid #1890ff;
  /* background-color: #fff; */
  width: 40%;
  height: 92px;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  gap: 0.5rem;
  padding: 16px;
  min-width: 260px;
  font-family: 'M PLUS 1';
  font-size: 20px;
}

.st-wallet:hover {
  border: 2px solid #1890ff;
}

.st-wallet-1 {
  border: 1px solid #1890ff;
  /* background-color: #fff; */
  width: 40%;
  height: 92px;
  display: flex;
  align-items: flex-end;
  align-content: center;
  cursor: pointer;
  gap: 0.5rem;
  padding: 16px;
  min-width: 260px;
  font-family: 'M PLUS 1';
  font-size: 20px;
}

.st-wallet-1:hover {
  border: 2px solid #1890ff;
}

.title__card {
  filter: drop-shadow(0 0 0.75rem #2ca0ffcc);
  font-weight: 400;
  font-size: 16px;
  /* word-break: break-all; */
  /* text-align: center; */
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.filter__common {
  position: relative;
  top: 87px;
}

.ant-menu .ant-menu-title-content {
  font-size: 16px;
}

.ant-modal .ant-modal-body {
  font-family: 'M PLUS 1';
}

.sidebar__profile .ant-menu .ant-menu-submenu {
  padding: 0;
  margin-bottom: 0;
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  -moz-user-drag: element;
  -webkit-user-drag: element;
  -khtml-user-drag: element;
  user-drag: element;
}

.skill_detail svg {
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
}

.skill_detail svg g {
  fill: transparent;
  fill-opacity: 0.6;
}

.skill_detail svg g:hover {
  fill-opacity: 1;
}

.skill_detail svg g polygon {
  stroke-width: 0.7;
  transition: fill-opacity 0.2s;
}

.skill_detail svg g .main polygon {
  stroke: #ffc600cc;
  filter: drop-shadow(0 0 1px rgba(255, 198, 0, 0.8));
}

.skill_detail svg g .sub polygon {
  stroke: #2ca0ffcc;
  filter: drop-shadow(0 0 1px rgba(44, 160, 255, 0.8));
}

.skill_detail svg g .pointSub polygon {
  stroke: #9d9d9c;
  filter: drop-shadow(0 0 1px rgba(157, 157, 156, 0.8));
}

.skill_detail .main {
  --tw-drop-shadow: drop-shadow(0 0 1.5px #ffc600cc);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.skill_detail .sub {
  --tw-drop-shadow: drop-shadow(0 0 1.5px #2ca0ffcc);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.skill_detail .pointSub {
  --tw-drop-shadow: drop-shadow(0 0 1.5px #9d9d9c);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.btnCheckSell[disabled] {
  cursor: not-allowed;
  border-color: #faa52c;
  background-color: #faa52c;
  box-shadow: none;
  color: #fff;
}
