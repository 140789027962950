.ant-btn {
  background-color: rgba(13, 39, 88, 1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 16px;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  opacity: 0.85;
}

.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background: rgba(13, 39, 88, 1);
  border: 1px solid rgba(24, 144, 255, 1);
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-title-content {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-active,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-drawer .ant-drawer-content {
  background: rgba(13, 39, 88, 1);
}

.ant-drawer .ant-drawer-title {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.ant-input-affix-wrapper {
  background: rgba(0, 17, 63, 1);
  color: #fff;
}

.ant-input-affix-wrapper > input.ant-input {
  font-family: 'M PLUS 1', sans-serif;
  font-weight: 500;
  background: rgba(0, 17, 63, 1);
  color: #fff;
}

.input__black .ant-input-affix-wrapper > input.ant-input {
  background: white;
  color: black;
}

.ant-input-suffix {
  font-family: 'M PLUS 1';
  font-weight: 700;
  margin-right: 7px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: rgba(191, 191, 191, 1) !important;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: rgba(191, 191, 191, 1) !important;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: rgba(191, 191, 191, 1) !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: rgba(191, 191, 191, 1) !important;
}

.ant-drawer .ant-drawer-header {
  border-bottom: 3px solid #3d5279;
}

.ant-popover .ant-popover-inner {
  background: #0d2758;
  border: 1px solid rgb(31, 96, 203, 0.8);
}

.ant-popover .ant-popover-inner-content {
  color: #fff;
}

.navbar__left .ant-menu-light,
.ant-menu-light > .ant-menu {
  background: #00091a;
  color: #fff;
}

.ant-menu-light,
.ant-menu-light > .ant-menu {
  background: transparent;
  color: #fff;
}

.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover,
.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  > .ant-menu-submenu-title:hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  > .ant-menu-submenu-title:hover {
  color: #40a9ff;
  border-bottom-color: #40a9ff;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #40a9ff;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-item-selected::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected::after {
  border-bottom-color: #40a9ff;
  inset-inline: 14px;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-open::after,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open::after,
.ant-menu-light
  > .ant-menu.ant-menu-horizontal
  > .ant-menu-submenu-open::after {
  border-bottom-color: #40a9ff;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-selected,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #40a9ff;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu:hover,
/* .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-active, */
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-active,
.ant-menu-light.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-item-open,
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open,
.ant-menu-light > .ant-menu.ant-menu-horizontal > .ant-menu-submenu-open {
  color: #40a9ff;
}

.isActiveAbout.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:nth-child(4)::after {
  border-bottom: none !important;
}
.isActiveAbout.ant-menu-light.ant-menu-horizontal
  > .ant-menu-item:nth-child(3)::after {
  border-bottom: none !important;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: #40a9ff;
}

.ant-menu-submenu-popup
  .ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
  background-color: #0d2758;
  color: #fff;
}

:where(.css-nnuwmp).ant-menu-light .ant-menu-item-selected,
:where(.css-nnuwmp).ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-menu .ant-menu-item,
.ant-menu .ant-menu-submenu,
.ant-menu .ant-menu-submenu-title {
  border-radius: 0;
  color: #fff;
}

.ant-menu-light.ant-menu-inline .ant-menu-selected::after,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-selected::after,
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-item-selected::after {
  border-right: 4px solid #40a9ff;
}

.ant-card {
  background: #0d2758;
  border: 1px solid #2ca0ffcc;
  box-shadow: 0px 0px 6px 0px #2ca0ffcc;
  color: #fff;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background: #1890ff;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background: #0d2758;
}

.ant-empty-normal .ant-empty-description {
  color: #fff;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  border-bottom: 1px solid #1890ff;
}

.ant-table-wrapper .ant-table-tbody > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-table-wrapper .ant-table {
  border-radius: 8px;
  background: #0d2758;
  border: 1px solid #2ca0ffcc;
  box-shadow: 0px 0px 6px 0px #2ca0ffcc;
  color: #fff;
}

.ant-table-tbody tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}

.ant-table-tbody tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > th,
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-wrapper .ant-table-tbody > tr > th.ant-table-cell-row-hover,
.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #000f38;
}

.ant-pagination {
  color: #fff;
  display: flex;
}

.ant-pagination .ant-pagination-prev button,
.ant-pagination .ant-pagination-next button {
  color: #fff;
}

.ant-pagination .ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination .ant-pagination-disabled:hover .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.25);
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: transparent;
  border: 1px solid #1890ff;
}

.ant-pagination.ant-pagination-simple .ant-pagination-prev,
.ant-pagination.ant-pagination-simple .ant-pagination-next {
  background: #1890ff;
  height: 32px;
  width: 32px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager {
  height: 32px;
  margin: 0 20px;
}

.ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
  background-color: transparent;
}

.pagination-item {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  background-color: #1890ff;
  border-radius: 3px;
  width: 32px;
  height: 32px;
}

.pagination-item:hover img {
  transform: scale(1.04);
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.5s;
}

.ant-pagination .ant-pagination-disabled,
.ant-pagination .ant-pagination-disabled:hover img {
  transform: scale(1) !important;
}

[class^='ant-pagination'] [class^='ant-pagination'],
[class*=' ant-pagination'] [class^='ant-pagination'],
[class^='ant-pagination'] [class*=' ant-pagination'],
[class*=' ant-pagination'] [class*=' ant-pagination'] {
  font-size: 18px;
  font-weight: 500;
}

.ant-progress .ant-progress-inner {
  background-color: transparent;
}

/* .ant-progress .ant-progress-success-bg,
.ant-progress .ant-progress-bg {
  border: 1px solid #fff;
} */

.ant-modal .ant-modal-content {
  padding: 0;
  background: linear-gradient(180deg, #243fa1 0%, #1b2f78 47.4%, #1b2f78 100%);
}

.ant-modal .ant-modal-header {
  color: #fff;
  background: linear-gradient(180deg, #305fb7 0%, #4586d7 100%);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.ant-modal .ant-modal-title {
  color: #fff;
}

.ant-modal .ant-modal-close {
  color: #fff;
}

.ant-modal .ant-modal-close:hover {
  color: #020f38;
}

.ant-modal-body {
  background: linear-gradient(180deg, #243fa1 0%, #1b2f78 47.4%, #1b2f78 100%);
  color: #fff;
  padding: 20px 24px !important;
  border-radius: 0 0 8px 8px;
}

.ant-modal .ant-modal-footer {
  padding: 20px 24px;
}

.ant-progress-line {
  margin: 0;
}

.ant-collapse {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.filter__common .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #fff;
  padding: 12px 0;
}

.ant-collapse .ant-collapse-content {
  color: #fff;
  background: transparent;
}

.filter__common .ant-collapse {
  border: none;
}

.filter__common .ant-collapse > .ant-collapse-item {
  border-bottom: 2px solid #3d5279;
}

.filter__common .ant-collapse .ant-collapse-content {
  border: none;
}

.filter__common .ant-collapse > .ant-collapse-item:last-child,
.filter__common
  .ant-collapse
  > .ant-collapse-item:last-child
  > .ant-collapse-header {
  border-radius: 0;
  border: none;
}

.filter__common
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 12px 0;
}

.ant-form-item .ant-form-item-label > label {
  color: #fff;
  font-size: 16px;
}

.ant-image-mask,
.ant-image-mask:hover {
  border-radius: 50%;
}

/* .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
background: #00113F;
} */
.ant-input {
  background: #00113f;
  border: 1px solid #00113f;
  color: #fff;
  font-family: 'M PLUS 1';
}

.ant-notification .ant-notification-notice {
  background: #0d2758;
  color: #ffffff;
}

.ant-notification .ant-notification-notice .ant-notification-notice-message {
  color: #fff;
}

.ant-notification .ant-notification-notice .ant-notification-notice-close {
  color: #fff;
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-close:hover {
  color: rgba(255, 255, 255, 0.5);
}

.ant-slider .ant-slider-track {
  background-color: #1890ff;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 4px #1890ff;
}

.ant-slider .ant-slider-rail {
  background-color: #eeeeee;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #eeeeee;
  opacity: 0.8;
}

.ant-slider {
  color: #fff;
}

.ant-input-number .ant-input-number-input {
  height: 100%;
}

[class^='ant-input'] [class^='ant-input'],
[class*=' ant-input'] [class^='ant-input'],
[class^='ant-input'] [class*=' ant-input'],
[class*=' ant-input'] [class*=' ant-input'] {
  height: 100%;
}

.ant-input-number .ant-input-number-input {
  font-family: 'M PLUS 1', sans-serif;
  background: #00113f;
  border: 1px solid #00113f;
  color: #fff;
}

.ant-input-number {
  border: 1px solid #00113f;
  background-color: transparent;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(
    90deg,
    rgba(53, 172, 254, 0.8) 25%,
    rgba(53, 172, 254, 0.5) 37%,
    rgba(53, 172, 254, 0.3) 63%
  );
  background-size: 400% 100%;
  animation-name: ant-skeleton-loading;
  animation-duration: 1.4s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.pagination__common {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination__common .ant-pagination {
  font-family: 'M PLUS 1', sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination__common .ant-pagination .ant-pagination-item a {
  color: #fff;
  font-size: 14px;
  font-family: 'M PLUS 1', sans-serif !important;
}

.ant-select-selection-item {
  font-family: 'M PLUS 1', sans-serif !important;
}

.pagination__common .ant-pagination .ant-pagination-item-active {
  background: transparent;
  padding: 6px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.pagination__common
  .ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.pagination__common
  .ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: rgba(255, 255, 255, 0.25);
}

.ant-select-single.ant-select-sm .ant-select-selector {
  background: #0d2758;
  border: 1px solid #1890ff;
  color: #fff;
  height: 32px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single.ant-select-sm.ant-select-open .ant-select-selection-item {
  color: #fff;
}

.ant-select-dropdown {
  background-color: #0d2758;
  border: 1px solid #1890ff;
  color: #fff;
}

.ant-select-dropdown .ant-select-item {
  color: #fff;
  font-family: 'M PLUS 1', sans-serif !important;
}

.ant-select-dropdown .ant-select-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

.ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
}

.ant-pagination .ant-pagination-options-quick-jumper input {
  border: 1px solid #1890ff;
  background-color: transparent;
  color: #fff;
  height: 32px !important;
}

.ant-popover .ant-popover-arrow:before {
  background: rgb(31, 96, 203, 0.8);
}

.ant-message .ant-message-notice .ant-message-notice-content {
  background: rgb(31, 96, 203);
  color: #fff;
  font-family: 700;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: fit-content;
}

.ant-spin-nested-loading .ant-spin-container::after {
  background: #010926;
}

.ant-select-single.ant-select-sm {
  height: 32px;
}

.ant-select .ant-select-arrow {
  color: #fff;
}

.ant-picker-panel-layout {
  background: #00113f;
}

.ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > span,
.ant-picker-dropdown .ant-picker-today-btn,
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-header button,
.ant-picker-dropdown .ant-picker-content th {
  color: #ffffff;
  font-family: 'M PLUS 1';
}

.ant-picker .ant-picker-input > input:placeholder-shown {
  font-family: 'M PLUS 1';
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  font-family: 'M PLUS 1';
}

.ant-form-item .ant-form-item-explain-error {
  font-family: 'M PLUS 1';
}

.scrollbar_container ::-webkit-scrollbar {
  height: 6px;
  display: block;
}

.scrollbar_container ::-webkit-scrollbar-track {
  border-radius: 2px;
  width: 2px !important;
}

.scrollbar_container ::-webkit-scrollbar-thumb {
  background: #1890ff;
  border-radius: 8px;
  width: 2px !important;
}

.ant-dropdown .ant-dropdown-menu {
  width: 200px;
}

.ant-menu-inline .ant-menu-submenu-arrow {
  display: none;
}

.ant-drawer .ant-drawer-body {
  padding: 0;
}

span.anticon.anticon-close {
  color: #ffffff;
  margin-left: -10px;
  font-size: 20px;
}

.input-numberSell {
  width: 100%;
  height: 32px;
  background: white;
  color: black;
  width: 100%;
  border: 0px solid #00113f;
}

.input-numberSell input {
  color: #000 !important;
  background: transparent !important;
}

.input-numberSell > .ant-input-number-handler-wrap {
  display: none;
}

.input-numberSell > .ant-input-number-input-wrap > input {
  background: white;
  color: black;
  width: 100%;
  border: 0px solid #00113f;
}

.ant-menu-light .ant-menu-item-selected a {
  color: #40a9ff;
}

.ant-form {
  color: #fff;
}

.ant-input-affix-wrapper {
  border-color: transparent;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector
  input {
  color: #fff;
}

#code_help {
  margin-top: 5px;
  width: 120px;
  max-width: 120px;
}

#phone_help {
  margin-left: 120px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #00113f;
  border: 0px solid #00113f;
  color: #fff;
  font-weight: 700;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff;
  font-weight: 700;
}

.ant-select .ant-select-selection-placeholder {
  color: rgb(177, 178, 182);
  font-family: 'M PLUS 1', sans-serif;
  font-weight: 700;
}

#ChangeLanguage
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
#ChangeLanguage
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  font-weight: 700;
}

#FormSendMail .ant-input-affix-wrapper .ant-input-suffix {
  margin: 0;
}

.ant-select-single.ant-select-sm:not(
    .ant-select-customize-input
  ).ant-select-show-arrow
  .ant-select-selection-search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnOTPPhone[disabled] {
  cursor: pointer;
  border-color: #d9d9d9;
  background-color: #fb923c;
  box-shadow: none;
}

.btnVerify[disabled] {
  cursor: not-allowed;
  border-color: #d9d9d9;
  background-color: #1890ff;
  opacity: 0.5;
  box-shadow: none;
  color: #fff;
}

.ReadOnly .ant-input-affix-wrapper > input.ant-input {
  background: #283e79;
}

.custom-popover > .ant-popover-arrow {
  margin-bottom: -30px !important;
}
.custom-popover > .ant-popover-content > .ant-popover-inner {
  margin-bottom: -30px !important;
}
.paramShop > .ant-card-body {
  padding: 10px 18px;
}
